@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "./themes/theme.scss";

body {
  font-family: "Proxima Nova Regular", sans-serif;
  margin: 0;
  line-height: 1.8px;
  overflow-x: hidden;
}

* {
  font-family: "Proxima Nova Regular", sans-serif;
}

body.modal-open {
  overflow: hidden;
}
