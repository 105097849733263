@font-face {
  font-family: "Proxima Nova Regular";
  src: url(.././core/assets/fonts/proximanova-regular-webfont.ttf);
  font-weight: 400;
}

@font-face {
  font-family: "Proxima Nova Bold";
  src: url(.././core/assets/fonts/mark_simonson_-_proxima_nova_alt_bold-webfont.ttf);
  font-weight: 800;
}

$fontWeight: (
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
);
$fontSize: (
  extraSmall: 12px,
  small: 14px,
  regular: 16px,
  medium: 18px,
  large: 20px,
  larger: 24px,
  extraLarge: 30px,
);

$colors: (
  white: #fff,
  darkWhite: #f3f2f7,
  black: #000,
  lightGrey: #e5e5e5,
  lighterGrey: rgba(130, 138, 143, 0.2),
  darkGrey: #828a8f,
  main: #9e2c44,
  orangeAccent: #f68621,
  blueAccent: #4aacd4,
  blueBackground: rgba(74, 172, 212, 0.2),
  redAccent: #df332f,
  greenAccent: #b0d20e,
  greenBackground: rgba(182, 208, 68, 0.2),
  yellowAccent: #ffcf01,
  borderColor: #333333,
  placeholderColor: rgba(51, 51, 51, 0.5),
  tableHeadGrey: #f3f2f7,
  greenStatus: #88a406,
  greenStatusBackground: rgba(176, 210, 14, 0.13),
  amberStatusBackground: rgba(160, 119, 81, 0.212),
  redStatus: #9e2c44,
  redStatusBackground: rgba(196, 109, 109, 0.212),
  boxShadow: #888888,
  projectBackground: #fae4d7,
  indicatorText: #404253,
  visibilityColorRed: rgba(158, 44, 68, 0.06),
  visibilityColorGreen: rgba(87, 87, 87, 0.06),
  searchIconBackground: #4aacd4,
  placeholder: #a2a5b5,
  orangeBtn: #e57836,
  tagBackground: #4aacd4,
  dropdownMenuBackground: #818e96,
  typeFilterBackground: rgba(158, 44, 68, 0.2),
  paleOrange: rgba(229, 120, 54, 0.2),
  paleYellow: rgba(248, 207, 71, 0.2),
  paleGreen: rgba(182, 208, 68, 0.2),
  paleBlue: rgba(74, 172, 212, 0.22),
  paleGrey: rgba(130, 138, 143, 0.2),
  paleRed: rgba(158, 44, 68, 0.2),
  greyBackground: rgba(0, 0, 0, 0.2),
  calendarBlue: #1a252f,
  blueBorder: rgb(13, 202, 240),
  futuresBackground: rgba(130, 138, 143, 0.2),
  osloGray: #838a8e,
);

$boxShadow: rgba(0, 0, 0, 0.4);
$btnBoxShadow: rgba(0, 0, 0, 0.25);

//////////// media queries ////////////////////
$extraSmall-width: 900px;
$desktop-width: 1200px;
$smallDesktop-width: 1400px;
$largeDesktop-width: 1600px;

$media_queries: (
  extraSmallDesktop:
    unquote(
      "only screen and (min-width: 400px) and (max-width: #{$extraSmall-width})"
    ),
  desktop:
    unquote(
      "only screen and (min-width: 400px) and (max-width: #{$desktop-width})"
    ),
  smallDesktop:
    unquote(
      "only screen and (min-width: 600px) and (max-width: #{$smallDesktop-width})"
    ),
  largerDesktop:
    unquote(
      "only screen and (min-width: 1401px) and (max-width: #{$largeDesktop-width})"
    ),
  xlDesktop: unquote("only screen and (min-width: 1601px)"),
);

@mixin for_breakpoint($breakpoints) {
  $conditions: ();
  @each $breakpoint in $breakpoints {
    $conditions: append(
      $conditions,
      #{inspect(map-get($media_queries, $breakpoint))},
      comma
    );
  }

  @media #{$conditions} {
    @content;
  }
}

///////////////////////////////////////////
